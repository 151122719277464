import React, { useEffect, useRef } from "react";

import styled, { keyframes } from "styled-components";

import flowersMobileLeft from "../images/flowers-dark_mobile.png";
import flowersTabletLeft from "../images/flowers-dark_tablet.png";
import flowersDesktopLeft from "../images/flowers-dark_desktop-landscape.png";
import flowersMobileRight from "../images/flowers-light_mobile.png";
import flowersTabletRight from "../images/flowers-light_tablet.png";
import flowersDesktopRight from "../images/flowers-light_desktop-landscape.png";

import displaceImg from "../images/displacement-filter-ripple2.jpeg";

const ImageContainer = styled.div`
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: space-between;

  pointer-events: none;

  img {
    display: none;
    width: 50%;

    object-fit: cover;
    object-position: top;
    z-index: 1;
  }

  canvas {
    display: none;
    z-index: 1;
  }

  .mobile-img {
    display: block;
  }

  @media (max-height: 700px) {
    top: -50px;
    height: calc(100% + 50px);
  }

  @media (min-width: 700px) {
    @media (max-height: 900px) {
      top: -225px;
      height: calc(100% + 225px);
    }

    @media (max-height: 1100px) {
      top: -225px;
      height: calc(100% + 225px);
    }

    .mobile-img {
      display: none;
    }

    .tablet-img {
      display: block;
    }
  }

  @media (min-width: 1200px) {
    .mobile-img {
      display: none;
    }

    .tablet-img {
      display: none;
    }

    // .desktop-img {
    //   display: block;
    // }

    canvas {
      display: block;
      width: 100%;
      max-width: 100%;
    }
  }
`;

let PIXI = {};
let app = {};

if (typeof document !== `undefined`) {
  PIXI = require("pixi.js");
  app = new PIXI.Application({ transparent: true, resizeTo: window });
}

export default function Flowers({}) {
  const containerRef = useRef(null);

  function setupFlowers() {
    if (containerRef?.current && app) {
      app.renderer.reset();

      function background(bgSize, inputSprite, type, forceSize) {
        var sprite = inputSprite;
        var bgContainer = new PIXI.Container();
        // var mask = new PIXI.Graphics()
        //   .beginFill(0x8bc5ff)
        //   .drawRect(0, 0, bgSize.x, bgSize.y)
        //   .endFill();
        // bgContainer.mask = mask;
        // bgContainer.addChild(mask);
        bgContainer.addChild(sprite);

        function resize() {
          var sp = { x: sprite.width, y: sprite.height };
          if (forceSize) sp = forceSize;
          var winratio = bgSize.x / bgSize.y;
          var spratio = sp.x / sp.y;
          var scale = 1;
          var pos = new PIXI.Point(0, 0);
          if (type == "cover" ? winratio > spratio : winratio < spratio) {
            //photo is wider than background
            scale = bgSize.x / sp.x;
            pos.y = -(sp.y * scale - bgSize.y) / 2;
          } else {
            //photo is taller than background
            scale = bgSize.y / sp.y;
            pos.x = -(sp.x * scale - bgSize.x) / 2;
          }

          sprite.scale = new PIXI.Point(scale, scale);
          sprite.position = pos;
        }

        resize();

        return {
          container: bgContainer,
          doResize: resize,
        };
      }

      try {
        app.renderer.resize(window.innerWidth, window.innerHeight);

        app.stage.removeChildren();
        // The application will create a renderer using WebGL, if possible,
        // with a fallback to a canvas render. It will also setup the ticker
        // and the root stage PIXI.Container.

        // The application will create a canvas element for you that you
        // can then insert into the DOM.

        containerRef.current.appendChild(app.view);

        app.stage.interactive = true;

        const container = new PIXI.Container();
        app.stage.addChild(container);

        const bgLeft = PIXI.Sprite.from(flowersDesktopLeft);
        const bgRight = PIXI.Sprite.from(flowersDesktopRight);

        const bgLeftBg = background(
          { x: app.screen.width / 2, y: app.screen.height },
          bgLeft,
          "cover",
          { x: app.screen.width / 2, y: app.screen.height }
        );

        const bgRightBg = background(
          { x: app.screen.width / 2, y: app.screen.height },
          bgRight,
          "cover",
          { x: app.screen.width / 2, y: app.screen.height }
        );

        bgLeft.width = app.screen.width / 2;
        bgRight.width = app.screen.width / 2;
        bgLeft.height = app.screen.height;
        bgRight.height = window.innerHeight;

        bgLeft.alpha = 1;

        app.width = app.screen.width;

        bgRightBg.container.position.x =
          app.screen.width - bgRightBg.container.width + 100;
        bgLeftBg.container.position.x = -100;

        container.addChild(bgLeftBg.container);
        container.addChild(bgRightBg.container);

        const displacementSpriteRight = PIXI.Sprite.from(displaceImg);
        displacementSpriteRight.position = bgRight.position;
        // Make sure the sprite is wrapping.
        // displacementSprite.texture.baseTexture.wrapMode =
        //   PIXI.WRAP_MODES.REPEAT;
        const displacementFilterRight = new PIXI.filters.DisplacementFilter(
          displacementSpriteRight
        );
        displacementFilterRight.padding = 10;

        displacementSpriteRight.visible = true;

        app.stage.addChild(displacementSpriteRight);
        displacementFilterRight.autoFit = !0;

        bgRight.filters = [displacementFilterRight];

        displacementFilterRight.scale.x = 5;
        displacementFilterRight.scale.y = 5;

        const displacementSpriteLeft = PIXI.Sprite.from(displaceImg);
        // Make sure the sprite is wrapping.
        displacementSpriteLeft.texture.baseTexture.wrapMode =
          PIXI.WRAP_MODES.REPEAT;
        displacementSpriteRight.texture.baseTexture.wrapMode =
          PIXI.WRAP_MODES.REPEAT;
        const displacementFilterLeft = new PIXI.filters.DisplacementFilter(
          displacementSpriteLeft
        );
        displacementFilterLeft.padding = 10;

        displacementSpriteLeft.visible = false;

        app.stage.addChild(displacementSpriteLeft);
        displacementFilterLeft.autoFit = !0;

        bgLeft.filters = [displacementFilterLeft];

        displacementFilterLeft.scale.x = 5;
        displacementFilterLeft.scale.y = 5;

        app.ticker.add((delta) => {
          // rotate the container!
          // use delta to create frame-independent transform
          displacementSpriteLeft.rotation -= 0.002 * delta;
          displacementSpriteRight.rotation += 0.001 * delta;
        });

        // const displacementSprite = PIXI.Sprite.from(displaceImg);
        // const displacementFilter = new PIXI.filters.DisplacementFilter(
        //   displacementSprite
        // );

        // app.stage.addChild(displacementSprite);

        // container.filters = [displacementFilter];

        // displacementFilter.scale.x = 110;
        // displacementFilter.scale.y = 110;
        displacementSpriteLeft.anchor.set(0.5);
        displacementSpriteRight.anchor.set(0.5);

        app.stage.on("mousemove", onPointerMove).on("touchmove", onPointerMove);

        function onPointerMove(eventData) {
          // console.log(
          //   eventData.data.global.x,
          //   app.screen.width,
          //   eventData.data.global.x < app.screen.width * 0.33
          // );

          // displacementSpriteLeft.position.x = eventData.data.global.x;
          // displacementSpriteLeft.position.y = eventData.data.global.y;
          // displacementSpriteRight.position.y = eventData.data.global.y;
          // displacementSpriteRight.position.y = eventData.data.global.y;

          if (eventData.data.global.x < app.screen.width * 0.33) {
            displacementSpriteLeft.visible = true;
          } else {
            displacementSpriteLeft.visible = false;
          }

          if (eventData.data.global.x > app.screen.width * 0.66) {
            displacementSpriteRight.visible = true;
          } else {
            displacementSpriteRight.visible = false;
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  useEffect(() => {
    setupFlowers();

    window.addEventListener("resize", () => {
      app.renderer.reset();
      setupFlowers();
    });

    return () => app.stop();
  }, [containerRef]);

  return (
    <ImageContainer ref={containerRef}>
      <img class="mobile-img" src={flowersMobileLeft} />
      <img class="mobile-img" src={flowersMobileRight} />
      <img class="tablet-img" src={flowersTabletLeft} />
      <img class="tablet-img" src={flowersTabletRight} />
      {/* <img class="desktop-img" src={flowersDesktopLeft} /> */}
      {/* <img class="desktop-img" src={flowersDesktopRight} /> */}
    </ImageContainer>
  );
}
